.nameInput {
    margin-right: 1.5rem;
}

.formContainer {
    margin-bottom: 1.5rem;
    max-width: 350px;
}

.formSection {
    padding: 10px 28px;
}

.header {
    font-size: var(--h1-size);
    padding: var(--card-header-padding);
}
