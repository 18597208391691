.header {
    padding: var(--card-header-padding);
    margin-bottom: var(--card-margin-y);
    word-break: break-all;
    border-bottom: var(--default-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header h1 {
    font-size: var(--h1-size);
}

.supporting {
    font-size: var(--caption-size);
    max-width: 450px;
}

.container {
    padding: var(--card-padding);
}

.formButtons {
    padding-top: 1rem;
}

.formContainer {
    margin-bottom: 1.5rem;
    max-width: 350px;
}
