.select {
    min-width: 100px;
}

.textfield {
    margin-left: 15px;
}

.header {
    padding: var(--card-header-padding);
    word-break: break-all;
    border-bottom: var(--default-border);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header h1 {
    font-size: var(--h1-size);
}

.container {
    padding: var(--card-padding);
}

.formButtons {
    padding-top: 1rem;
}

.tagListItem {
    padding: 0;
}

.tagListItem * > a {
    text-decoration: none;
    color: inherit;
}

.tagTypeContainer {
    max-width: 350px;
}

.addTagTypeForm {
    display: flex;
    flex-direction: column;
}
