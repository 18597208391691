.header {
    display: flex;
    padding: var(--card-header-padding);
    justify-content: space-between;
    align-items: center;
    border-bottom: var(--default-border);
}

.heading {
    font-size: var(--h1-size);
}

.featureInfoContainer {
    padding: var(--card-header-padding);
}

.selectContainer {
    margin-top: 1rem;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--card-header-padding);
    border-bottom: var(--default-border);
    border-top: var(--default-border);
}

.tabContentContainer {
    padding: var(--card-padding);
}
