.item {
    flex: 1;
    width: 100%;
    margin: 5px 0 5px 5px;
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;
}

.card {
    width: 100%;
    display: block;
    background-color: #f2f9fc;
    overflow: 'visible';
}

.paragraph {
    max-width: 400px;
}

.cardText {
    width: unset;
    padding: 0 10px 10px;
}

.isDragging {
    opacity: 0.2;
    border: 2px dotted black;
}

.isDroptarget {
    border: 2px solid rgba(96, 125, 139, 1) !important;
}

.item:nth-child(odd) {
    margin-left: 0;
}

@media (max-width: 1024px) {
    .item {
        margin: 2px;
    }
}

@media (max-width: 500px) {
    .item {
        min-width: unset;
        margin: 2px;
    }
}

/*
.item:not(:first-child):after {
    content: " OR ";
    position: absolute;
    left: -30px;
    top: 45%;
    color: #ccc;
    width: 25px;
    line-height: 32px;
    font-size: 14px;
    text-align: center;
    height: 100%;
    z-index: 2;
}
*/

.actionButton {
    color: #fff;
}

.cardTitle {
    color: #fff;
    background-color: var(--primary);
}

.helpText {
    color: rgba(0, 0, 0, 0.54);
    font-size: 12px;
    line-height: 14px;
    margin: 0.5rem 0;
}

.editLink {
    color: #fff;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
}

.reorderIcon {
    cursor: pointer;
    display: inline-block;
    vertical-align: bottom;

    &.disabled {
        cursor: default;
        background-color: #f1f1f1;
        color: #f1f1f1;
    }
}

.strategyListAdd {
    padding: var(--card-padding);
    margin-top: 2rem;
}

.strategyList {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 2rem;
}

.strategyListCards {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    margin-top: 2rem;
    padding: 1.5rem 0;
}

.infoCard {
    margin-top: 2rem;
}

.strategyListCards > * {
    margin: 0.25rem;
}

.strategyListAdd {
    background-color: #f9f9f9;
}

.generalSection {
    margin: 1rem 0;
}

/*
    ADD STRATEGY
*/
.addStrategyButton {
    font-size: 0.935rem;
}

.subheader {
    color: #fff;
}

.isDirty {
    background-color: #f3e5f5;
}

@media (max-width: 500px) {
    .strategyList {
        padding: 0;
    }
}

@media (max-width: 860px) {
    .strategyListCards {
        flex-direction: column;
        flex-wrap: none;
        align-items: center;
    }
}
