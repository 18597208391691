.header {
    font-size: var(--h1-size);
    padding: var(--card-header-padding);
}

.container {
    padding: var(--card-padding);
}

.nameInput {
    margin-right: 1.5rem;
    min-width: 250px;
}

.formContainer {
    margin-bottom: 1.5rem;
    max-width: 350px;
}

.legalValueFormContainer {
    display: flex;
}

.toggleContainer {
    display: flex;
    align-items: center;
}

.strategiesContainer {
    margin: 2rem 0;
}
