.chip {
    display: inline-flex;
    vertical-align: middle;
    margin-left: 30px;
    position: relative;
    z-index: 1;
    overflow: visible;
}

.chip:first-child {
    margin-left: 0;
}

.chip:not(:first-child):after {
    content: " OR ";
    position: absolute;
    left: -27px;
    top: 0;
    color: #ccc;
    width: 25px;
    line-height: 32px;
    font-size: 14px;
    text-align: center;
    height: 100%;
    z-index: 2;
}

.chip:first-child:before {
    content: "";
}

.problemIcon {
    width: 100px;
    height: 100px;
    font-size: 100px !important;
    color: #ccc;
}

.grid {
    text-align: center;
}
