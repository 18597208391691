.path {
    stroke: #5eb89b;
    stroke-linecap: round;
    transition: stroke-dashoffset 5s ease 0s;
}

.trail {
    stroke: #888888;
}

.text {
    fill: currentColor;
    font-size: 24px;
    line-height: 24px;
    dominant-baseline: middle;
    text-anchor: middle;
}
